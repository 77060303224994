import img1 from "./assets/img/1.jpeg";
import img2 from "./assets/img/2.jpeg";
import img3 from "./assets/img/3.jpeg";
import img4 from "./assets/img/4.jpeg";
import img5 from "./assets/img/5.jpeg";
import img6 from "./assets/img/6.jpeg";
import img7 from "./assets/img/7.jpeg";
import img8 from "./assets/img/8.jpeg";
import img9 from "./assets/img/9.jpeg";
import img10 from "./assets/img/10.jpeg";
import img11 from "./assets/img/11.jpeg";
import img12 from "./assets/img/12.jpeg";
import img13 from "./assets/img/13.jpeg";
import img14 from "./assets/img/14.jpeg";
import img15 from "./assets/img/15.jpeg";
import img16 from "./assets/img/16.jpeg";
import img17 from "./assets/img/17.jpeg";

import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="max-w-5xl mx-auto px-5">
        <ul className="pt-16 flex justify-center gap-5 md:gap-10">
          <li>
            <a className="bg-black py-2 px-4 rounded-md text-white">Telegram</a>
          </li>
          <li>
            <a className="bg-black py-2 px-4 rounded-md text-white">Twitter</a>
          </li>
          <li>
            <a
              href="https://medium.com/@NohMask_Erc"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-black py-2 px-4 rounded-md text-white"
            >
              Medium
            </a>
          </li>
        </ul>
        <div className="py-10">
          <p>
            The traditional Japanese mask "Noh mask" is known as "Noh-men" in
            English. It is a type of mask used in the traditional Japanese art
            form called Noh theater. Noh theater is an ancient form of Japanese
            dance-drama renowned for its refined movements, music, and dramatic
            performances.
            <br />
            <br />
            The "Noh mask" is a mask worn by actors during performances to
            portray various characters and emotions. These masks are crafted by
            skilled artisans, typically carved from wood using intricate carving
            techniques. Each mask has its own unique design and characteristics.
            <br />
            <br />
            Noh masks are exquisitely designed, featuring rich colors and
            representing different types of characters such as gods, demons,
            warriors, and more. They are considered works of art and are widely
            used in Noh theater performances and other traditional ceremonial
            events.
            <br />
            <br />
            The "Noh mask" showcases the essence of traditional Japanese culture
            and is an integral part of Japanese art and performance.
          </p>
        </div>
        <div className="py-10">
          <ul className="flex flex-col gap-12">
            <li className="flex flex-col gap-7">
              <p>
                “桥姬”
                因嫉妒而变为鬼女的人。面为红色，眼球周围的颜色是深红色。咧嘴瞪眼，狰狞可怕。用于能剧《铁轮》。
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img1} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>
                “能面”
                是日本传统戏剧艺术“能”剧所使用面具。能面尊古法使用木（多为桧木）雕刻，再上色彩而成，这个工序称之为【打脸】，而后面的戴面具被称之为【挂脸】。能面分为翁面、老人面、鬼神面、女面、男面、灵面、特殊面等几大类型。
                <br />
                <br />
                让我们从广为人知的“般若”面具说起
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img2} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>
                日本传说中“般若”
                是人（通常是女人）因为嫉妒心而幻化成的鬼怪。因为嫉妒心，而导致自己的灵魂在自己活着的情况下离开自己身体，并且灵魂能攻击甚至杀死自己嫉妒的人。其栖居于山林中，于半夜出没吃人，会发出令人毛骨悚然的可怕笑声。
                <br />
                <br />
                日本能面中的“泥眼”、“生成”、“般若”表现的就是女性因嫉妒心生怨念变为般若的过程。
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img3} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>
                泥眼（在いがん）眼里面涂有泥金。是个嫉妒狂。在能剧《葵上》中出现过。
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img4} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>“生成” 女性因嫉妒和怨念变得扭曲，头上长出了角。</p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img5} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>
                “般若”
                女性因为嫉妒心而幻化成的鬼怪。因为嫉妒心，而导致自己的灵魂在自己活着的情况下离开自己身体，并且灵魂能攻击甚至杀死自己嫉妒的人。
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img6} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>
                蛇（じゃ） /
                真蛇（しんじゃ），怨念幻化的终极形态，是比般若更加可怕的鬼怪，他没有耳朵，有“已经什么也听不进去”的意思。
                <br />
                <br />
                能面多以鬼怪为原型，在能剧中艺术家通过肢体语言展现一段段故事，“能面为能之生命”，据说有600年历史了，能乐有戴面具和不带面具的表演形式，通常主角才能戴。
                <br />
                <br />
                能演员饰演普通男性人物时不戴假面，也不化装，叫做“直面”。但饰演武将、公卿贵族、少年、盲人等人物时使用假面。
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img7} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>
                蝉丸（せみまる）天皇的儿子，双目失明。因此常用来饰演贵族少年和盲人等人物时使用。
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img8} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>“大童子” 用于少年</p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img9} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>
                法性寺僧都俊宽。因密谋反叛平家而被流放到鬼界岛，在失意与严重营养不良的交困下衰朽至极的形象。充满愤恨和欲望的双眼兼有消沉之色，因消瘦造成面部骨骼极其突出。
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img10} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>
                “小喝食”
                原型是禅寺中吃饭时站在食堂的一角大声喊唱菜名的少年，用于具有艺术气质的美少年。
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img11} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>猩猩，通体涂红的能面，在日本能剧中指一种嗜酒的海上瑞兽。</p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img12} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>
                “弱法师”在能剧中属杂能，讲述的是弱法师因被人中伤而被父亲赶出家门，过度伤心导致失明，又因腿脚无力故被人称为弱法师，后和父亲迟释前嫌的故事。
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img13} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>
                十六面，据说此面是按十六岁战死的平氏家族的平敦盛的相貌制作的。比中将面、今若面年轻。
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img14} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>“老女” 用于老年女性。</p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img15} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>
                “小面”中的“小”包含着年轻、可爱、美丽的意思。“小面”面在女面中是最年轻的面，脸颊丰润，眼鼻嘴集中在脸的中央部，额头宽下颚长，给人的感觉非常纯真。
              </p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img16} alt="img" />
              </div>
              <p>▲</p>
            </li>
            <li className="flex flex-col gap-7">
              <p>若女（年轻的老板娘）年龄比小面要大</p>
              <div className="max-w-2xl mx-auto">
                <img className="w-full" src={img17} alt="img" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
